// import { Routes, Route } from "react-router-dom";
// import Layout from "./components/Layout";
// import Homepage from "./pages/home";
// function App() {
//   return (
//     <>
//       <Routes>
//         <Route path="/" element={<Layout />}>
//           <Route index element={<Homepage />} />
//         </Route>
//       </Routes>
//     </>
//   );
// }

// export default App;


import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import HttpsRedirect from 'react-https-redirect';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import Home from "./components/home/home";
import Dashboard from "./components/dashboard/dashboard";
import Header from './components/header/Header';
import Footer from "./components/footer/Footer";

const App = () =>{
  
  return (
    <div className="App">
      <div id="page" className="layout-fullwidth header-style-vertical">
        <a href="#page" className="scroll-top-button"></a>
        <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Redirect to="/" />
        </Switch>
        <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
