import { ToastContainer, toast } from "react-toastify";
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import WalletLink from 'walletlink';
const { ethereum } = window;
const web3 = new Web3(ethereum);

class Web3Service {
  	
  	getConfigJson = async () => {
	    const configResponse = await fetch("/config/config.json", {
	      headers: {
	        "Content-Type": "application/json",
	        Accept: "application/json",
	      },
	    });
	    const config = await configResponse.json();
	    return config;
	}

	getAbiJson = async () => {
	    const abiResponse = await fetch("/config/abi.json", {
	      headers: {
	        "Content-Type": "application/json",
	        Accept: "application/json",
	      },
	    });
	    const abi = await abiResponse.json();
	    return abi;
	}

	getNftContractObj = async () => {
	    var configData = await this.getConfigJson();
	    var abiData = await this.getAbiJson();
	    const configResponse = await fetch("/config/config.json", {
	      headers: {
	        "Content-Type": "application/json",
	        Accept: "application/json",
	      },
	    });
	    const CONFIG = await configResponse.json();
	    const NFTContractObj = new Web3EthContract(
	      abiData,
	      configData.CONTRACT_ADDRESS
	    );
	    return NFTContractObj;
	}

	checkPublicSaleOpen = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.publicSaleOpen().call(function (error, result) {
				console.log("publicSaleOpen", result);
				resolve(result);
			});
		});
	}

	checkWhitelistSaleOpen = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.whitelistSaleOpen().call(function (error, result) {
                console.log("whitelistSaleOpen", result);
                resolve(result);
			});
		});
	}

	checkWhiteListed = async (accountAddress) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.whiteListed(accountAddress).call(function (error, result) {
                console.log("whiteListed", result);
                resolve(result);
			});
		});
	}

	getMaxMintPublicAmount = async (accountAddress) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.max_mint_public().call(function (error, result) {
                resolve(result);
			});
		});
	}

	getMintPrice = async (accountAddress) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.mintprice().call(function (error, result) {
                resolve(web3.utils.fromWei(result));
			});
		});
	}

	getMultisigWallet = async (accountAddress) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.multisigWallet().call(function (error, result) {
                resolve(result);
			});
		});
	}

	getMaxMintWhitelisted = async (accountAddress) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.max_mint_whitelisted().call(function (error, result) {
                resolve(result);
			});
		});
	}
	
	checkTotalSupply = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			console.log(NFTContractObj);
			await NFTContractObj.methods.totalSupply().call(function (error, result) {
                console.log(error);
                resolve(result);
			});
		});
	}

	getIpfsURI = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.ipfsURI().call(function (error, result) {
                resolve(result);
			});
		});
	}

	getBaseTokenURI = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			await NFTContractObj.methods.baseTokenURI().call(function (error, result) {
                resolve(result);
			});
		});
	}
	
	getPastLogs = async () => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			let block = await web3.eth.getBlock('latest');
			
			await NFTContractObj.getPastEvents("Transfer", { fromBlock: block.number-4900},function(error, events){
                resolve(events);
			})
			.then(function(events){
				console.log(events);
			}).catch((err) => console.error(err));
		});
	}

	mintNFT = async (accountAddress, mintAmount) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			var value = 0;
			if(configData.ADMIN_ADDRESS.toLowerCase() != accountAddress.toLowerCase()){
				
				let cost = await this.getMintPrice();
				console.log(cost * mintAmount);
				let totalCostWei = String(cost * mintAmount);
				console.log(totalCostWei);
				var value = web3.utils.toWei(""+totalCostWei);
			}
			console.log(value);
			await NFTContractObj.methods.mint(mintAmount)
		      .send({
		        to: configData.CONTRACT_ADDRESS,
				from: accountAddress,
		        value: value
		      })
		      .once("error", (error) => {
		      	resolve({"status":"error", "message": error.message});
		      })
		      .then((receipt) => {
		      	resolve({"status":"success", "message": "NFT Minted sucessfully."});
		      });
		});
	}

	setNFTMintingClaim = async (accountAddress, mintAmount) => {
		console.log(mintAmount);
		return new Promise(async resolve => {
	        const configData = await this.getConfigJson();
	        var publicSellOpen = false;
	        var whitelistSaleOpen = false;
	        var addedIntoWhitelist = false;
	        console.log(`Minting your ${configData.NFT_NAME}...`);
	        if(configData.ADMIN_ADDRESS.toLowerCase() != accountAddress.toLowerCase()){
				var publicSellOpen = await this.checkPublicSaleOpen();
				var whitelistSaleOpen = await this.checkWhitelistSaleOpen();
				var addedIntoWhitelist = await this.checkWhiteListed(accountAddress);
				if(whitelistSaleOpen){
					if(!addedIntoWhitelist){
						resolve({"status":"error", "message": "Sorry, Your account not added in whistlist sale open. Please try after some time."});
						return;
					}
				}else if(!publicSellOpen){
					resolve({"status":"error", "message": "Sorry, Public sell not open for now. Please try after some time."});
					return;
				}
			}
			var mintResponse = await this.mintNFT(accountAddress, mintAmount);
			resolve(mintResponse);
		});
	}
  	
  	getUserTokensBalance = async (userAddress) => {
  		return new Promise(async resolve => {	
  			const NFTContractObj = await this.getNftContractObj();
  			await NFTContractObj.methods.balanceOf(userAddress).call(function (error, result) {
				
				if (error) {
					resolve({"status":"error", "message":"Sorry something is wrong", "tokens": 0});
				} else {
					resolve({"status":"success", "message": "Data load", "tokens": web3.utils.fromWei(result)});
				}
			});
		});
  	}

	setWhitelistSaleOpen = async (status) => {
		console.log("status", status);
	  	return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setWhitelistSaleOpen(status)
		      .send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
		      .once("error", (error) => {
		      	resolve({"status":"error", "message": error.message});
			})
		      .then((receipt) => {
		      	resolve({"status":"success", "message": "Status updated sucessfully."});
			});
		});
	}

	setPublicSaleOpen = async (status) => {
		console.log("status", status);
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setPublicSaleOpen(status)
		      .send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
		      .once("error", (error) => {
		      	resolve({"status":"error", "message": error.message});
			})
		      .then((receipt) => {
		      	resolve({"status":"success", "message": "Status updated sucessfully."});
			});
		});
  	}

  	setMintPrice = async (price) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setMintPrice(web3.utils.toWei(price))
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}

  	setMaxMintPublic = async (maxMintAmount) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setMaxMintPublic(maxMintAmount)
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}
	
  	setMaxMintWhitelisted = async (maxMintAmount) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setMaxMintWhitelisted(maxMintAmount)
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}

  	setMultisigWallet = async (address) => {
		return new Promise(async resolve => {
			console.log("address", address);
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setMultisigWallet(address)
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}

  	setBaseURI = async (IpfsURIValue, BaseTokenURI) => {
		return new Promise(async resolve => {
			console.log("IpfsURIValue", IpfsURIValue);
			console.log("BaseTokenURI", BaseTokenURI);
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setBaseURI(IpfsURIValue, BaseTokenURI)
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}

  	setWhitelistStatus = async (whitelistStatusValue1, whitelistStatusValue2) => {
		return new Promise(async resolve => {
			console.log("whitelistStatusValue1", whitelistStatusValue1);
			console.log("whitelistStatusValue2", whitelistStatusValue2);
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.setWhitelistStatus([whitelistStatusValue1], [whitelistStatusValue2])
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}
	
	setAirDrop = async (addresses, tokenids) => {
		return new Promise(async resolve => {
			const NFTContractObj = await this.getNftContractObj();
			const configData = await this.getConfigJson();
			const userAccount = localStorage.getItem("userAccount");
			await NFTContractObj.methods.doAirDrop(configData.ADMIN_ADDRESS,[addresses], [tokenids])
			.send({
		        to: configData.CONTRACT_ADDRESS,
				from: userAccount,
		        value: 0
			})
			.once("error", (error) => {
				resolve({"status":"error", "message": error.message});
			})
			.then((receipt) => {
				resolve({"status":"success", "message": "Set Whitelist Sale Open."});
			});
	  	});
  	}


}

export default new Web3Service();