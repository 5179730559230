// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import WalletLink from 'walletlink';

// log
import { fetchData } from "../data/dataActions";
const { ethereum } 	= 	window;
const Web3Modal 	= 	window.Web3Modal.default;
let web3 = new Web3(ethereum);

const WalletConnectProvider = window.WalletConnectProvider.default;

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		options: {
			rpc: {
				56: 'https://bsc-dataseed.binance.org/'
			},
			network: "binance",
			chainId: 56,
			infuraId: '3c7eb7651b334a76ac5ba3386e523b15' // change this to come from config
		},
    }
	/* mewconnect: {
		package: MewConnect, // required
		options: {
		  infuraId: environment.infuraId // required
		}
	},
    fortmatic: {
      package: Fortmatic,
      options: {
        key: environment.fortmatickey
      }
    } */
};

const web3Modal 			= 	new Web3Modal({
								cacheProvider: false, // optional
								providerOptions, // required
								network: 'binance', // change this to come from config
								disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
							});
let provider;
let subscribed	=	false;
const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const switchNetwork = async (ethereum, CONFIG) => {
  try {
    if (CONFIG.NETWORK_MODE == "live") {
      // check if the chain to connect to is installed
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
      });
    } else {
		console.log("switchNetwork");
      // check if the chain to connect to is installed
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers
      });
    }
  } catch (error) {
    if (CONFIG.NETWORK_MODE == "live") {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                "chainName": 'Binance Mainnet',
                "blockExplorerUrls": ['https://bscscan.com/'],
                "chainId": '0x38',
                "rpcUrls": ['https://bsc-dataseed.binance.org/'],
                "nativeCurrency": {
                  "name": "BNB",
                  "symbol": "BNB",
                  "decimals": 18
                }
              },
            ],
          });
        } catch (addError) {

        }
      }
    } else {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                "chainName": 'Binance Testnet',
                "blockExplorerUrls": ['https://testnet.bscscan.com'],
                "chainId": '0x61',
                "rpcUrls": ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                "nativeCurrency": {
                  "name": "BNB",
                  "symbol": "BNB",
                  "decimals": 18
                }
              },
            ],
          });
        } catch (addError) {

        }
      }
    }
  }
};

const refreshAccounts = async () => {
	
	let accounts	=	[];
	if(typeof web3 !="undefined"){
		// Checking if Web3 has been injected by the browser (Mist/MetaMask)
		// Use Mist/MetaMask's provider
		
		await web3.eth.getAccounts((err: null, accs: any | any[] | undefined) => {
			
			if (err != null) {
				console.warn('There was an error fetching your accounts.');
				return;
			}
			// Get the initial account balance so it can be displayed.
			if (accs.length === 0) {
				console.warn('Couldn\'t get any accounts! Make sure your Ethereum client is configured correctly.');
				return;
			}
			if (!accounts || accounts.length !== accs.length || accounts[0] !== accs[0]) {
				console.log('Observed new accounts');
				accounts = accs;
			}
		});
	}
	return accounts;
}

const subscribeToChange = async (dispatch) => {
	console.log("provider", "provider");
	console.log(provider);
	if(!subscribed){
		console.log("provider", provider);
		subscribed	=	true;
		if(typeof provider.off !== 'undefined' ){
			provider.off("accountsChanged");
			provider.off("chainChanged");
			provider.off("networkChanged");
		}
		if(typeof provider.on !== 'undefined' ){
			// Subscribe to accounts change
			provider.on("accountsChanged", (accounts: any) => {
				console.log("accountsChanged");
				refreshAccounts();
				dispatch(updateAccount(accounts[0]));
			});
			// Subscribe to chainId change
			provider.on("chainChanged", (chainId: any) => {
				console.log("chainChanged");
				dispatch(connect('metamask'));
			});

			// Subscribe to networkId change
			provider.on("networkChanged", (networkId: any) => {
				console.log("networkChanged");
				dispatch(connect('metamask'));
			});
		}
	}
	// Add listeners end
}

export const connect = (connecttype) => {
  
	return async (dispatch) => {
		dispatch(connectRequest());
		const abiResponse = await fetch("/config/abi.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const abi = await abiResponse.json();
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const CONFIG = await configResponse.json();
		if(connecttype == "metamask") {
			const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
			if (metamaskIsInstalled) {
				
				try {
					await ethereum.enable();
					var delay = new Promise(resolve => setTimeout(resolve, 100));
					await delay;
					
					const networkId = await web3.eth.net.getId();
					const chainId = await web3.eth.getChainId();
					if(ethereum.constructor.name !="TrustWeb3Provider"){
						await switchNetwork(ethereum, CONFIG);
						refreshAccounts();
					}else{
						if (networkId == CONFIG.NETWORK.ID) {
							dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
							return;
						}
					}				
					const networkId2 = await web3.eth.net.getId();
					console.log("networkId", networkId2);
					if (networkId2 == CONFIG.NETWORK.ID) {
						const accounts =  await refreshAccounts();
						provider		=	web3.currentProvider;
						Web3EthContract.setProvider(provider);
						const NFTContractObj = new Web3EthContract(
							abi,
							CONFIG.CONTRACT_ADDRESS
						);
						subscribeToChange(dispatch);
						localStorage.setItem("connecttype", connecttype);
						dispatch(
							connectSuccess({
								account: accounts[0],
								accountType: "login",
								nftContract: NFTContractObj,
								web3: web3,
							})
						);
					} else {
						dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
					}
				} catch (err) {
					dispatch(connectFailed("Something went wrong."));
				}
			} else {
				dispatch(connectFailed("Install Metamask."));
			}
		}else if(connecttype == "myether") {
			
			const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
			if (metamaskIsInstalled) {
				
				try {
					await ethereum.enable();
					var delay = new Promise(resolve => setTimeout(resolve, 100));
					await delay;
					
					const networkId = await web3.eth.net.getId();
					const chainId = await web3.eth.getChainId();
					if(ethereum.constructor.name !="TrustWeb3Provider"){
						await switchNetwork(ethereum, CONFIG);
					}else{
						if (networkId == CONFIG.NETWORK.ID) {
							dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
							return;
						}
					}				

					if (networkId == CONFIG.NETWORK.ID) {
						const accounts =  await refreshAccounts();
						provider		=	web3.currentProvider;
						Web3EthContract.setProvider(provider);
						const NFTContractObj = new Web3EthContract(
							abi,
							CONFIG.CONTRACT_ADDRESS
						);
						subscribeToChange(dispatch);
						localStorage.setItem("connecttype", connecttype);
						dispatch(
							connectSuccess({
								account: accounts[0],
								accountType: "login",
								nftContract: NFTContractObj,
								web3: web3,
							})
						);
					} else {
						dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
					}
				} catch (err) {
					dispatch(connectFailed("Something went wrong."));
				}
			} else {
				dispatch(connectFailed("Install Metamask."));
			}
		} else if(connecttype == "trustwallet") {
			
			const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
			if (metamaskIsInstalled) {
				
				try {
					await ethereum.enable();
					var delay = new Promise(resolve => setTimeout(resolve, 100));
					await delay;
					
					const networkId = await web3.eth.net.getId();
					const chainId = await web3.eth.getChainId();
					if(ethereum.constructor.name !="TrustWeb3Provider"){
						await switchNetwork(ethereum, CONFIG);
					}else{
						if (networkId == CONFIG.NETWORK.ID) {
							dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
							return;
						}
					}				

					if (networkId == CONFIG.NETWORK.ID) {
						const accounts =  await refreshAccounts();
						provider		=	web3.currentProvider;
						Web3EthContract.setProvider(provider);
						const NFTContractObj = new Web3EthContract(
							abi,
							CONFIG.CONTRACT_ADDRESS
						);
						subscribeToChange(dispatch);
						localStorage.setItem("connecttype", connecttype);
						dispatch(
							connectSuccess({
								account: accounts[0],
								accountType: "login",
								nftContract: NFTContractObj,
								web3: web3,
							})
						);
					} else {
						dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
					}
				} catch (err) {
					dispatch(connectFailed("Something went wrong."));
				}
			} else {
				dispatch(connectFailed("Install Metamask."));
			}
		} else if(connecttype == "walletconnect") {
			
			const ETH_JSONRPC_URL = CONFIG.COINBASE_ETH_JSONRPC_URL+CONFIG.infuraId;
			const CHAIN_ID = CONFIG.COINBASE_CHAIN_ID;
			provider = await web3Modal.connectTo(connecttype);
			web3 = new Web3(provider);					
			subscribeToChange(dispatch);
			localStorage.setItem("connecttype", connecttype);
			const accounts =  await refreshAccounts();
			
			Web3EthContract.setProvider(provider);
			const NFTContractObj = new Web3EthContract(
				abi,
				CONFIG.CONTRACT_ADDRESS
			);
			
			console.log(provider);
			dispatch(
				connectSuccess({
					account: accounts[0],
					accountType: "login",
					nftContract: NFTContractObj,
					web3: web3,
				})
			);
		}
	};
};

export const updateAccount = (account) => {
	return async (dispatch) => {
		dispatch(updateAccountRequest({ account: account }));
		dispatch(fetchData(account));
	};
};
